<template>
  <div class="enterprise-company">
    <el-row>
      <el-form label-width="auto" :inline="true" size="mini">
        <el-col :span="8">
          <el-form-item label="企业名称:">
            <el-input placeholder="请输入企业全称" class="order-input-filter" clearable v-model="pagination.name"
              @change="loadCompany" prefix-icon="el-icon-search"></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="6" :offset="6">
          <el-form-item label="排序方式:">
            <el-select placeholder="请选取排序方式" v-model="pagination.orderType" class="order-input-filter"
              @change="loadCompany" size="mini">
              <el-option v-for="item in orderTypes" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="mini" plain @click="handleFlushCompany">检索客户</el-button>
          <el-button type="primary" size="mini" @click="handleCreateCompanyVisible">新增客户</el-button>
        </el-col>
      </el-form>
    </el-row>
    <el-table :data="companies">
      <el-table-column label="序号" type="index" width="50px"></el-table-column>
      <el-table-column label="申请企业" prop="name" align="center"></el-table-column>
      <el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
      <el-table-column label="申请时间" align="center">
        <template slot-scope="scope">
          {{ new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss') }}
        </template>
      </el-table-column>

      <el-table-column label="操作人" width="100px" prop="auditor" align="center"></el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.status === 0">
            <el-button type="text" @click="handleAgree(scope.row)">同意注册</el-button>
            <el-button type="text" class="danger">拒绝</el-button>
          </template>
          <template v-else-if="scope.row.status === -1">
            <el-tooltip :content="scope.row.reason">
              <span class="danger" @mouseover="handleLoadReason(scope.row)">审核拒绝
              </span>
            </el-tooltip>
          </template>
          <template v-else-if="scope.row.status === 1">
            <span class="success" @click="handleEnterpriseChange(scope.row.uuid)">代为运营</span>

            <span class="edit btn" @click="handleEditInfo(scope.row)">信息编辑</span>
          </template>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @current-change="handleCurrentChange" class="pagination" :current-page="pagination.curPage"
      :page-size="pagination.limit" layout="total, prev, pager, next, jumper" :total="pagination.total">
    </el-pagination>

    <c-dialog :visible.sync="addCompanyVisible" :title="'新增客户'" :width="'40rem'" :height="'24rem'">
      <div class="create-company">
        <el-form label-width="100px" size="small">
          <el-form-item label="客户名称">
            <el-input v-model="company.name"></el-input>
          </el-form-item>

          <el-form-item label="客户手机">
            <el-input v-model="company.phone"></el-input>
          </el-form-item>

          <el-form-item label="登录密码">
            <el-input v-model="company.password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码">
            <el-input v-model="company.repeatedPassword"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleCreateCompany">立即创建</el-button>
            <el-button @click="addCompanyVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </c-dialog>
  </div>

</template>

<script>
import {
  enterpriseAuditUnion,
  enterpriseAuditList,
  enterpriseCreate,
  enterpriseAuditPass,
  enterpriseCtrl
} from '@/api/factory/account/enterprise'
import CDialog from '@/components/Dialog'
import MapContainer from '@/components/Map'
import { mapGetters } from "vuex";
import { checkObj, checkPhone } from "@/util/checkLogin";
import { setToken } from '@/util/auth'

export default {
  name: '',
  components: {
    CDialog,
    MapContainer
  },
  computed: {
    ...mapGetters(['employee'])
  },
  mounted: function () {
    this.loadCompany()
  },

  data: function () {
    return {
      value: false,
      addCompanyVisible: false,
      pagination: {
        name: '',
        province: '',
        city: '',
        curPage: 1,
        limit: 10,
        total: 0,
        orderType: 0,
      },
      company: {
        name: '',
        phone: '',
        password: '',
        repeatedPassword: '',
      },
      orderTypes: [],
      makeTimeRange: [],
      companies: [],
      employees: [],
    }
  },
  methods: {
    handleAgree(item) {
      let { name, uuid } = item
      this.$confirm('<p>确认要通过“' + name + '”的注册</p> <p class="tips">通过后该企业可正常使用系统功能</p>', '温馨提示',
        { dangerouslyUseHTMLString: true, center: true, confirmButtonText: '通过审核' }).then(() => {
          return enterpriseAuditPass({ name })
        }).then(res => {
          if (res.code === 0) {
            this.$message.success('操作成功')
            this.loadCompany()
          }
        }).catch(err => {
          console.error(err)
        })
    },
    handleEditInfo(row) {
      this.$router.push({ name: 'system.enterprise.detail', params: { enterprise: row } })
    },
    loadCompany() {
      enterpriseAuditUnion(this.pagination).then(res => {
        this.companies = res.data.list || []
        this.pagination.total = parseInt(res.data.total)
      })
    },
    handleEnterpriseChange(uuid) {
      enterpriseCtrl({ uuid: uuid }).then(res => {
        setToken(res.data)
        window.location.href = "/"
      })
    },
    handleLoadReason(item) {
      let { uuid } = item
      if (!item.reason) {
        GetReason({ uuid }).then(res => {
          if (res.code === 0) {
            this.$set(item, 'reason', res.data)
          }
        })
      }
    },
    handleCurrentChange(value) {
      this.pagination.curPage = value
      this.pagination.start = (value - 1) * this.pagination.limit
      enterpriseAuditList(this.pagination).then(res => {
        this.companies = res.data || []
      })
    },
    handleFlushCompany() {
      this.loadCompany()
      this.$message.success('加载成功')
    },

    handleCreateCompanyVisible() {
      this.company = {
        name: '',
        phone: '',
        password: '',
        repeatPassword: '',
      }
      this.addCompanyVisible = true
    },
    handleCreateCompany() {
      let check = checkObj(this.company, [
        { value: 'name', message: '请输入公司名称' },
        { value: 'phone', message: '请输入手机号' },
        { value: 'password', message: '请输入密码' },
        { value: 'repeatedPassword', message: '请输入重复密码' },
      ])

      if (!check.status) {
        this.$message.warning(check.message)
        return
      }

      check = checkPhone(this.company.phone)
      if (!check.status) {
        this.$message.warning(check.message)
        return
      }

      if (this.company.password !== this.company.repeatedPassword) {
        this.$message.warning('两次密码不一致')
        return
      }
      enterpriseCreate(this.company).then(res => {
        this.companies.unshift({
          uuid: res.data,
          name: this.company.name,
          phone: this.company.phone,
          status: 0,
          createdAt: (new Date().getTime()) / 1000,

        })
        this.$message.success('创建成功')
        this.addCompanyVisible = false

      })
    }
  },
}
</script>

<style lang="scss">
body.dark {
  .dealer-company {
    .el-input__inner {
      border-color: #858992;
    }
  }
}

.enterprise-company {

  padding: $padding;

  .danger {
    color: $btn_sec_color;
  }

  .success {
    color: $--color-success-lighter;
    cursor: pointer;
  }

  .edit {
    color: $btn_color;
  }

  .btn {
    margin-left: $padding;
    cursor: pointer;
  }


  .create-company {
    padding-top: $padding;
    padding-right: $padding;

    .el-input__inner {
      max-width: 180px;
    }

    .map {
      height: 240px;
    }

    .explain {
      font-size: 12px;
      display: inline-block;
      margin-left: $padding-8;
      @include set_font_color($--font-03-color, $--font-color-dark);
    }
  }


}
</style>
